import {
    Object3D,
    PlaneGeometry,
    Mesh,
    ShaderMaterial,
    Vector2,
} from "three"
import fragmentShader from '@/assets/shaders/markerFrag.glsl'
import vertexShader from '@/assets/shaders/markerVert.glsl'
export default class Marker {
    constructor(params) {
        this.position = params.position
        this.camera = params.camera
        this.to = params.to
        this.assets = params.assets
        this.time = params.time
        this.debug = params.debug
        this.label = params.label
        this.name = params.name
        this.visible = true
        this.material = null
        this.mesh = null
        this.size = 0.5;
        this.container = new Object3D()
        this.container.name = 'Marker'
        this.init()

    }
    init() {
        const geo = new PlaneGeometry(this.size, this.size, 10, 10)
        this.material = new ShaderMaterial({
            fragmentShader: fragmentShader,
            vertexShader: vertexShader,
            transparent: true,
            uniforms: {
                uResolution: {
                    value: new Vector2(500, 500)
                },
                uTime: {
                    value: 0
                },
                uDistance: {
                    value: null
                }
            }
        })
        this.mesh = new Mesh(geo, this.material)
        this.mesh.name = 'Marker ' + this.name
        this.mesh.to = this.to
        this.mesh.position.copy(this.position)
        this.container.add(this.mesh)

        this.time.on('tick', this.handleTick.bind(this))
    }

    handleTick() {
        this.material.uniforms.uTime.value += 0.01
        let distance = this.position.distanceTo(this.camera.container.position)
        this.material.uniforms.uDistance.value = distance

        if (distance < 2 && this.visible) {
            this.visible = false
            this.mesh.position.y = -5
        }
        else if (distance > 2 && !this.visible) {
            this.visible = true
            this.mesh.position.y = this.position.y
        }
        // const factor =  * Math.min(1.9 * Math.tan(Math.PI * this.camera.camera.fov / 360) / this.camera.camera.zoom, 7);
        this.mesh.scale.set(1, 1, 1)
        this.mesh.lookAt(this.camera.container.position)

    }
}
