import { Vector3 } from "three";
import BottleHandler from "./BottleHandler";
import store from '../store/index'
import gsap from 'gsap'
import RaycastManager from "./RaycastManager"; export default class Controls {
  constructor(params) {
    this.camera = params.camera;
    this.time = params.time;
    this.scene = params.scene;
    this.postProcessing = params.postProcessing;
    this.assets = params.assets
    this.world = params.world;
    this.target = new Vector3()
    this.percentage = 0
    this.forward = true
    this.notice = true
    this.debug = document.querySelector('.currentProgression')
    this.init()
  }
  init() {
    this.raycastManager = new RaycastManager({
      camera: this.camera,
      scene: this.scene,
      time: this.time,
      postProcessing: this.postProcessing,
      world: this.world,
      assets:this.assets
    });


    this.bottleHandler = new BottleHandler({
      time: this.time,
      camera: this.camera,
      postProcessing: this.postProcessing,
      scene: this.scene,
      raycastManager:this.raycastManager
    });

    document.addEventListener("touchstart", this.touchStart.bind(this));
    document.addEventListener("touchmove", this.touchMove.bind(this));
    document.addEventListener("touchend", this.touchEnd.bind(this));

    document.addEventListener("mousedown", this.mouseDown.bind(this));
    document.addEventListener("mouseup", this.mouseUp.bind(this));
    document.addEventListener("mousemove", this.mouseMove.bind(this));
    document.addEventListener('wheel', this.wheel.bind(this))
    this.mouseIsDown = false;

    this.coord = { x: 0, y: 0 };
    this.rotateSpeed = 0.003;
  }
  wheel(e) {
    if (this.bottleHandler.selectedBottle) return
    if(this.notice) {
      this.notice = false
      gsap.to('.notice', {opacity:0, delay:0.5})
    }
    if (e.deltaY < 0) this.forward = false
    else this.forward = true
    this.camera.scrolling = true
    const factor = 0.0001
    this.percentage += e.deltaY * factor
    if (this.percentage > 1) this.percentage = 0
    if (this.percentage < 0) this.percentage = 1
    this.camera.forward = this.forward
    this.camera.moveToPercentage(this.percentage)
    // this.debug.innerText = this.percentage + ' - position: new Vector3('+p1.x.toPrecision(3)+', 1.75,'+p1.z.toPrecision(3)+')'


  }
  touchStart(e) {
    this.cast(e.touches[0]);
    this.trySelectBottle();
    this.coord = { x: e.touches[0].clientX, y: e.touches[0].clientY };
  }

  touchEnd() {
    this.tryMove();
  }

  touchMove(e) {
  this.rotate(e.touches[0]);
  }

  mouseDown(e) {
    this.cast(e)
    this.mouseIsDown = true;
    this.trySelectBottle();
    if (this.bottleHandler.selectedBottle && store.getters.currentPage === 'product') {
      document.body.style.cursor = "grabbing";
    }
    else {
      document.body.style.cursor="default"
    }
    this.coord = { x: e.clientX, y: e.clientY };
  }

  mouseUp() {
    this.mouseIsDown = false;
    this.tryMove();

    if (this.bottleHandler.selectedBottle && store.getters.currentPage === 'product') {
      document.body.style.cursor = "grab";
    }
    else {
      document.body.style.cursor="default"

    }
  }

  mouseMove(e) {
    if (this.mouseIsDown) this.rotate(e);
    else this.cast(e);
  }

  rotate(e) {
    console.log(store.getters.currentPage)
    if (this.bottleHandler.selectedBottle && store.getters.currentPage === 'product') this.rotateBottle(e);
    if(store.getters.currentPage === 'explore') this.rotateCamera(e);
  }

  rotateCamera(e) {
    this.camera.scrolling = false
    this.disableMove();

    // let angleX = (e.clientY - this.coord.y) * this.rotateSpeed;
    let angleY = (e.clientX - this.coord.x) * this.rotateSpeed;

    // this.camera.camera.rotateX(angleX);
    this.camera.camera.rotateOnWorldAxis(new Vector3(0.0, 1.0, 0.0), angleY);

    this.coord = { x: e.clientX, y: e.clientY };
  }

  rotateBottle(e) {
    let angleX = (e.clientY - this.coord.y) * this.rotateSpeed;
    let angleY = (e.clientX - this.coord.x) * this.rotateSpeed;

    let camQuat = this.camera.camera.quaternion;
    let contQuat = this.camera.container.quaternion;

    let cameraUp = new Vector3(0, 1, 0).applyQuaternion(camQuat).applyQuaternion(contQuat);
    let cameraRight = new Vector3(1, 0, 0).applyQuaternion(camQuat).applyQuaternion(contQuat);

    // console.table(cameraUp)

    this.bottleHandler.selectedBottle.rotateOnWorldAxis(cameraRight, angleX);
    this.bottleHandler.selectedBottle.rotateOnWorldAxis(cameraUp, angleY);

    this.coord = { x: e.clientX, y: e.clientY };
  }

  cast(e) {
    if (this.bottleHandler.selectedBottle) return;
    this.coord = { x: e.clientX, y: e.clientY };
    document.body.style.cursor = "auto";

    this.raycastManager.cast(this.coord.x, this.coord.y);
  }

  tryMove() {
    if (!this.raycastManager.canMove) return;
    this.camera.moveToPercentage(this.raycastManager.direction)
    this.percentage = this.raycastManager.direction
  }

  disableMove() {
    this.raycastManager.canMove = false;
    this.raycastManager.pointer.visible = false;
  }

  trySelectBottle() {

    if (this.raycastManager.hasSelected || !this.raycastManager.canSelect) return;
    this.raycastManager.hasSelected=true
    this.bottleHandler.selectBottle(this.raycastManager.ghostBottle);
  }

  exit() {
    if (this.bottleHandler.selectedBottle) this.bottleHandler.unselectBottle();
  }
}
