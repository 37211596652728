import {
    LinearToneMapping,
    sRGBEncoding,
    WebGLRenderer
} from "three"

export default class Renderer {
    constructor(canvas, scene, camera) {
        this.canvas = canvas
        this.scene = scene
        this.camera = camera
        this.init()
    }
    init() {
        this.renderer = new WebGLRenderer({
            canvas: this.canvas,
            antialias: true,
            alpha: true,
            powerPreference: 'high-performance',
            precision: 'highp'
        })
        // this.renderer.physicallyCorrectLights = true;
        this.renderer.outputEncoding = sRGBEncoding;
        this.renderer.textureEncoding = sRGBEncoding;
        this.renderer.toneMapping = LinearToneMapping
        this.renderer.toneMappingExposure = 2
        this.renderer.setSize(window.innerWidth, window.innerHeight)
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    }
    resize() {
        this.renderer.setSize(window.innerWidth, window.innerHeight)
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    }
    render() {
        this.renderer.render(this.scene, this.camera.camera)
    }
}