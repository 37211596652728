import {
    Object3D
} from "three";
import Marker from './Marker';
export default class Markers {
    constructor(params) {
        this.markers = params.markers ? params.markers : []
        this.time = params.time
        this.camera = params.camera
        this.assets = params.assets
        this.debug = params.debug
        this.raycast = params.raycast
        this.container = new Object3D()
        this.container.name = 'Markers Container';
        this.init()
    }
    init() {
        this.markers.forEach(marker => this.createMarker(marker))
    }
    createMarker(marker) {
        const markerObject = new Marker({
            time: this.time,
            camera: this.camera,
            assets: this.assets,
            position: marker.position,
            debug: this.debug,
            to: marker.to,
            label: marker.label,
            raycast: this.raycast,
            name:marker.name
        })
        this.container.add(markerObject.container)
    }
    handleTick() {

    }
}
