<template>
  <div class="loading">
    <div class="progress">
      <div class="progress-track">
        <div class="progress-bar"></div>
      </div>
      <span class="percentage"></span>
    </div>
  </div>
</template>
