import { Object3D, PlaneBufferGeometry } from "three";
import CustomReflector from './CustomRefractor';

export default class Ground {
    constructor(params) {
        this.gallery = params.gallery
        this.container = new Object3D()
        this.texture = params.texture

        this.originalGround = this.gallery.mesh.getObjectByName('SOL')

        this.originalGround.visible = false

        this.geo = new PlaneBufferGeometry(50, 50)

        this.ground = new CustomReflector(this.geo, {
            clipBias: 0.003,
            textureWidth: window.innerWidth * window.devicePixelRatio * 0.25,
            textureHeight: window.innerHeight * window.devicePixelRatio * 0.25,
            color: 0x222222,
            map: this.texture
        });

        this.ground.rotateX(-Math.PI / 2)

        this.container.add(this.ground);
        window.addEventListener('resize', this.onWindowResize.bind(this));
    }

    onWindowResize() {
        this.ground.renderTarget.setSize(
            window.innerWidth * window.devicePixelRatio * 0.25,
            window.innerHeight * window.devicePixelRatio * 0.25
        );
    }


}