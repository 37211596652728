import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import DefaultLayout from "@/layouts/default.vue";
import ThreeLayout from "@/layouts/three.vue";
import "@/assets/styles/_app.scss";
const app = createApp(App)
  .use(store)
  .use(router);
app.component("default-layout", DefaultLayout);
app.component("three-layout", ThreeLayout);
app.mount("#app");
