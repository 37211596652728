import {
    GLTFLoader
} from 'three/examples/jsm/loaders/GLTFLoader'
import {
    TextureLoader,
    AudioLoader
} from 'three';
import {
    DRACOLoader
} from './draco/DRACOLoader'
export default class Loader {
    constructor() {
        this.gltf = new GLTFLoader()
        this.draco = new DRACOLoader()
        this.texture = new TextureLoader()
        this.sound = new AudioLoader()
        this.imports = {
            models: [],
            shaders: [],
            textures: [],
            sounds: []
        }
        this.waiter = []
        this.models = {}
        this.textures = {}
        this.shaders = {}
        this.sounds = {}
        this.total = 0
        this.progress = 0
        this.init()
    }
    init() {
        this.draco.setDecoderPath('/draco/')
        this.gltf.setDRACOLoader(this.draco)
        const modelsContext = require.context('@/assets/models', true, /\.(glb|gltf|fbx)$/)
        modelsContext.keys().forEach((key) => {
            const newKey = `${key}`.substring(2)
            // eslint-disable-next-line
            const modelSrc = require('@/assets/models/' + newKey)
            this.imports.models.push({
                name: key.substring(
                    2,
                    key.length - (key.length - newKey.lastIndexOf('.') - 2)
                ),
                src: modelSrc.default
            })
        })
        const shadersContext = require.context('@/assets/shaders', true, /\.(glsl|vert|frag)$/)
        shadersContext.keys().forEach((key) => {
            const newKey = `${key}`.substring(2)
            // eslint-disable-next-line
            const shaderSrc = require('@/assets/shaders/' + newKey)
            this.imports.shaders.push({
                name: key.substring(
                    2,
                    key.length - (key.length - newKey.lastIndexOf('.') - 2)
                ),
                src: shaderSrc.default
            })
        })
        const soundsContext = require.context('@/assets/sounds', true, /\.(mp3|ogg|wav)$/)
        soundsContext.keys().forEach((key) => {
            const newKey = `${key}`.substring(2)
            // eslint-disable-next-line
            const soundsSrc = require('@/assets/sounds/' + newKey)
            this.imports.sounds.push({
                name: key.substring(
                    2,
                    key.length - (key.length - newKey.lastIndexOf('.') - 2)
                ),
                src: soundsSrc.default
            })
        })
        const texturesContext = require.context('@/assets/textures', true, /\.(png|jpeg|jpg)$/)
        texturesContext.keys().forEach((key) => {
            const newKey = `${key}`.substring(2)
            const texturesSrc = require('@/assets/textures/' + newKey)
            this.imports.textures.push({
                name: key.substring(
                    2,
                    key.length - (key.length - newKey.lastIndexOf('.') - 2)
                ),
                src: texturesSrc
            })
        })
        this.total = this.imports.shaders.length + this.imports.textures.length + this.imports.sounds.length + this.imports.models.length
    }
    load() {
        this.loadModels()
        this.loadTextures()
        this.loadShaders()
        this.loadSounds()
        return new Promise((resolve, reject) => {
            try {
                Promise.all(this.waiter).then(() => setTimeout(() => {
                    resolve()
                }, 1000))
            } catch (e) {
                reject(e)
            }
        })
    }
    loadModels() {
        this.imports.models.forEach(importedModel => {
            const promise = new Promise(resolve => {
                this.gltf.load(importedModel.src, (model) => {
                    this.models[importedModel.name] = model
                    this.progress++
                    document.querySelector('.percentage').innerText = ((this.progress / this.total) * 100).toFixed(1) + '%'
                    document.querySelector('.progress-bar').style.width = ((this.progress / this.total) * 100).toFixed(1) + '%'
                    resolve()
                })
            })
            this.waiter.push(promise)
        })
    }
    loadTextures() {
        this.imports.textures.forEach(importedTexture => {
            const promise = new Promise(resolve => {
                this.texture.load(importedTexture.src, (texture) => {
                    this.textures[importedTexture.name] = texture
                    this.progress++
                    document.querySelector('.percentage').innerText = ((this.progress / this.total) * 100).toFixed(1) + '%'
                    document.querySelector('.progress-bar').style.width = ((this.progress / this.total) * 100).toFixed(1) + '%'
                    resolve()
                })
            })
            this.waiter.push(promise)
        })
    }
    loadShaders() {
        this.imports.shaders.forEach(importedShader => {
            const promise = new Promise(resolve => {
                this.shaders[importedShader.name] = importedShader.src
                this.progress++
                document.querySelector('.percentage').innerText = ((this.progress / this.total) * 100).toFixed(1) + '%'
                document.querySelector('.progress-bar').style.width = ((this.progress / this.total) * 100).toFixed(1) + '%'
                resolve()
            })
            this.waiter.push(promise)
        })
    }
    loadSounds() {
        this.imports.sounds.forEach(importedSound => {
            const promise = new Promise((resolve, reject) => {
                this.sound.load(importedSound.src, (sound) => {
                    this.sounds[importedSound.name] = sound
                    this.progress++
                    document.querySelector('.percentage').innerText = ((this.progress / this.total) * 100).toFixed(1) + '%'
                    document.querySelector('.progress-bar').style.width = ((this.progress / this.total) * 100).toFixed(1) + '%'
                    resolve()
                }, null, e => {
                    reject(e)
                })
            })
            this.waiter.push(promise)
        })
    }
}