import EventEmitter from './EventEmitter.js'

export default class Time extends EventEmitter {
    /**
     * Constructor
     */
    constructor(params) {
        super()
        this.config = params.config
        this.start = Date.now()
        this.current = this.start
        this.elapsed = 0
        this.delta = 16
        this.tick = this.tick.bind(this)
        this.tick()
    }

    /**
     * Tick
     */
    tick() {
        this.ticker = window.requestAnimationFrame(this.tick)
        if (!this.config.renderOnBlur && !document.hasFocus()) return
        else {
            const current = Date.now()
            this.delta = current - this.current
            this.elapsed = current - this.start
            this.current = current

            if (this.delta > 60) {
                this.delta = 60
            }
            this.trigger('tick')
        }

    }

    /**
     * Stop
     */
    stop() {
        window.cancelAnimationFrame(this.ticker)
    }
}