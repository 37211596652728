import { Scene, EquirectangularReflectionMapping, sRGBEncoding } from "three";
import Camera from "./Camera";
import Renderer from "./Renderer";
import Controls from "./Controls";
import Time from "./Tools/Time";
import Loader from "./Tools/Loader";
import World from "./World";
import * as Stats from "stats.js";
import * as dat from "dat.gui";
import PostProcessing from "./PostProcessing";
import { ethers } from "ethers";
import axios from "axios";
import NFT from '../contracts/NFT.json'
import Market from '../contracts/NFTMarket.json'
import Web3 from "web3";
export default class App {
  constructor(params) {
    this.canvas = params.canvas;
    this.config = params.config;
    this.store = params.store;
    this.time = new Time({
      config: params.config,
    });
    if (window.location.hash.includes("dev")) this.setDebug();
    this.BLOOM_SCENE = 20
    this.init();
  }
  async init() {
    if (window.location.hash.includes("marketplace")) this.tokens = await this.loadTokens()
    this.scene = new Scene();
    this.camera = new Camera({
      debug: this.debug,
      time: this.time,
      scene: this.scene,
    });
    this.scene.add(this.camera.container)
    this.renderer = new Renderer(this.canvas, this.scene, this.camera);
    this.camera.renderer = this.renderer;

    this.renderer.physicallyCorrectLights = true;
    this.renderer.outputEncoding = sRGBEncoding;


    this.postProcessing = new PostProcessing({
      renderer: this.renderer,
      time: this.time,
      camera: this.camera,
      scene: this.scene,
      BLOOM_SCENE: this.BLOOM_SCENE
    });

    this.assets = new Loader();
    try {
      await this.assets.load();
      this.store.commit("setLoaded", true);
      this.world = new World({
        time: this.time,
        assets: this.assets,
        debug: this.debug,
        camera: this.camera,
        tokens: this.tokens,
        BLOOM_SCENE: this.BLOOM_SCENE
      });
      this.scene.add(this.world.container);
      this.hdri = this.assets.textures.hdri;
      this.hdri.mapping = EquirectangularReflectionMapping;
      this.hdri.encoding = sRGBEncoding;
      this.scene.environment = this.hdri;
      this.setControls();
      window.addEventListener("resize", this.onResize.bind(this));
    } catch (e) {
      console.error("[TWN Starter] An error occured during assets loading.");
      console.error(e);
    }
  }
  loadTokens() {
    return new Promise((resolve) => {
      const provider = ethers.getDefaultProvider("ropsten", {
        // infura: {
          infra:process.env.VUE_APP_INFURA_ID,
          // projectSecret:process.env.VUE_APP_INFURA_SECRET
        // }
      });
      console.log(provider)
      const marketContract = new ethers.Contract(
        process.env.VUE_APP_MARKETPLACE_ADRESS,
        Market.abi,
        provider
      );
  
        marketContract.fetchMarketItems().then(async data => {
          const contract = new ethers.Contract(
            process.env.VUE_APP_TOKEN_ADRESS,
            NFT.abi,
            provider
          );
          const items = await Promise.all(
            data.map(async i => {
              const uri = await contract.tokenURI(i.tokenId)
              const data =await axios.get(uri)
              data.data.tokenId = i.tokenId
              data.data.id = parseInt(data.data.name.split('#')[1])
              data.data.price = Web3.utils.fromWei(i.price.toString(), "ether");
    
              return data.data
            })
          )
          resolve({
            vintage:items.filter(item => item.name.includes('Vintage')),
            rose:items.filter(item => item.name.includes('Rosé'))   
          })
       
      })

  })
  }
  onResize() {
    this.camera.resize();
    this.renderer.resize();
    this.postProcessing.resize();
    this.controls.bottleHandler.resize()
  }
  render() {
    if (this.stats) this.stats.begin();
    this.renderer.render();
    if (this.stats) this.stats.end();
  }
  setControls() {
    this.controls = new Controls({
      time: this.time,
      camera: this.camera,
      scene: this.scene,
      postProcessing: this.postProcessing,
      world: this.world,
      assets:this.assets
    });
    window.controls = this.controls
  }
  setDebug() {
    this.stats = new Stats();
    document.body.appendChild(this.stats.dom);
    this.debug = new dat.GUI();
  }
}
