<template>
  <canvas ref="canvas"></canvas>
  <slot />
  <transition name="fade">
    <Loading v-if="!loaded" />
  </transition>
</template>
<script>
import Loading from "@/components/Loading.vue";
import { ref, onMounted } from "vue";
import App from "@/three/App";
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  name: "default",
  components: { Loading },
  setup() {
    const canvas = ref(null);
    const store = useStore();
    onMounted(() => {
      new App({
        canvas: canvas.value,
        config: {
          renderOnBlur: true
        },
        store
      });
    });

    return {
      canvas,
      store,
      loaded: computed(() => store.getters.loaded)
    };
  }
};
</script>
