<template>
  <div class="home">
    <Header />
    <div class="landing">
      <img
        ref="bottle"
        src="@/assets/images/bottle_rose.png"
        alt="Dom Pérignon x Lady Gaga Rosé Millésime 2006"
        class="bottle"
      />
      <div class="landing-content">
        <h1 data-scroll ref="title">Une collaboration unique</h1>
      </div>
      <div class="landing-actions">
        <router-link to="/explore">Commencer l'expérience</router-link>
        <span>Scroller pour en savoir +</span>
      </div>
    </div>
    <div class="page-content">
      <div data-aos="fade-down" data-aos-duration="2000" class="section">
        <small>une unique</small>
        <h2>collaboration</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </p>
      </div>
      <div data-aos="fade-down" data-aos-duration="2000" class="section">
        <small>un concept en</small>
        <h2>édition limitée</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </p>
      </div>
      <div class="video">
        <h2>Découvrez le Queendom</h2>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Deserunt vel
          ratione mollitia expedita autem nihil culpa, vitae repellendus.
          Commodi reiciendis distinctio quas adipisci maxime quos accusantium
          veritatis molestiae minus fugit.
        </p>
        <video src="@/assets/videos/homepage.mp4"></video>
      </div>
    </div>
    <footer>
      <ul>
        <li><a href="#">S'abonner</a></li>
        <li><a href="#">Nous contacter</a></li>
        <li><a href="#">Conditions d'utilisation</a></li>
        <li><a href="#">Politique de confidentialité</a></li>
        <li><a href="#">Recommendations de conservation du champagne</a></li>
        <li><a href="#">Langue</a></li>
        <li><a href="#">Pays/Région</a></li>
      </ul>
      <span>© DOM PÉRIGNON</span>
    </footer>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import gsap from "gsap";
import { onMounted, ref } from "vue";
import SplitType from "split-type";
import AOS from "aos";
export default {
  components: { Header },
  name: "Home",
  setup() {
    const bottle = ref(null);
    const title = ref(null);

    onMounted(() => {
      AOS.init();
      const titleSplit = new SplitType(title.value);
      const timeline = gsap.timeline();
      timeline.from(titleSplit.chars, {
        delay: 0.4,
        opacity: 0,
        y: 100,
        stagger: 0.02,
        ease: "back",
        duration: 2
      });
      timeline.from(bottle.value, { opacity: 0, duration: 3 }, "-=2");
      timeline.from("header", { opacity: 0, duration: 1 }, "-=2");
    });
    return {
      bottle,
      title
    };
  }
};
</script>
