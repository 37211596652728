import {
    // AmbientLight,
    HemisphereLight,
    Object3D
} from "three";

export default class Lights {
    constructor() {
        this.container = new Object3D()

        this.hemisphereLight = new HemisphereLight(0xffffff, 0xaaaaaa, 2)
        // this.ambientLight = new AmbientLight(0xffffff, 1)
        this.hemisphereLight.layers.enable(3)
        this.container.add(this.hemisphereLight)
    }
}