<template>
  <component :is="layout + '-layout'">
    <router-view></router-view>
  </component>
</template>
<script>
import { computed } from "vue";
import { useRoute } from "vue-router";
export default {
  setup() {
    let route = useRoute();

    return {
      layout: computed(() => route.meta.layout)
    };
  }
};
</script>
