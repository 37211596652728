import { PerspectiveCamera, Quaternion, Vector3, WebGLRenderer } from "three";
import store from '../store/index';
import gsap from 'gsap'
export default class BottleHandler {
  constructor(params) {
    this.time = params.time;
    this.camera = params.camera;
    this.scene = params.scene;
    this.targetPosition = new Vector3();
    this.raycastManager = params.raycastManager
    this.selectedBottle = null;
    this.originalBottlePosition = new Vector3(0, 0, 0);
    this.originalBottleQuaternion = new Quaternion(0, 0, 0, 0);

    this.unselectedBottles = [];

    this.distanceFromCam = 5.65;
    this.init();
    this.time.on("tick", this.animate.bind(this));
    window.addEventListener('resize', this.resize.bind(this))
  }
  init() {
    this.bottleCamera = new PerspectiveCamera(
      80,
      window.innerWidth / window.innerHeight,
      0.01,
      1000
    );
    this.camera.container.add(this.bottleCamera)
    this.bottleCamera.setFocalLength(20)

    this.bottleCamera.rotation.copy(this.camera.camera.rotation);
    this.bottleCamera.layers.toggle(3);
    this.bottleCamera.layers.disable(0);
    this.bottleRenderer = new WebGLRenderer({
      canvas: document.querySelector(".bottle"),
      antialias: true,
      alpha: true,
      powerPreference: "high-performance",
      precision: "highp",
    });
    this.bottleRenderer.setSize(window.innerWidth, window.innerHeight);
    this.bottleRenderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

  }
  resize() {
    this.bottleRenderer.setSize(window.innerWidth, window.innerHeight)
    this.bottleRenderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    this.bottleCamera.aspect = window.innerWidth / window.innerHeight;
    this.bottleCamera.updateProjectionMatrix();
    this.bottleCamera.setFocalLength(20)
  }
  animate() {
    // this.animateSelected();
    // this.animateUnselected();
    // this.bottleCamera.position.copy(this.camera.container.position);
    this.bottleCamera.quaternion.copy(this.camera.camera.quaternion);
    this.bottleRenderer.render(this.scene, this.bottleCamera);
  }

  animateSelected() {
    if (!this.selectedBottle) return;
    this.selectedBottle.position.lerp(this.targetPosition, 0.1);
  }

  animateUnselected() {
    if (this.unselectedBottles.length == 0) return;
    for (let i = 0; i < this.unselectedBottles.length; i++) {
      let b = this.unselectedBottles[i];
      let p = b.originalPosition;
      let q = b.originalQuaternion;
      b.bottle.position.lerp(p, 0.15);
      b.bottle.quaternion.slerp(q, 0.15);
      if (
        b.bottle.position.distanceTo(p) < 0.05 &&
        b.bottle.quaternion.angleTo(q) < 0.05
      ) {
        b.bottle.position.set(p.x, p.y, p.z);
        b.bottle.quaternion.set(q.x, q.y, q.z, q.w);
        this.unselectedBottles.splice(i, 1);
      }
    }
  }

  selectBottle(bottle) {
    console.log('bottle type', bottle)
    if (bottle === this.selectedBottle) return;
    if (this.selectedBottle) this.unselectBottle();
    bottle.traverse(node => {
      node.layers.set(3);
    })
    store.commit('setBottle', bottle.token)
    store.commit('setCurrentPage', 'product')
    let bottleSize = bottle.geometry.boundingSphere.radius;
    let distance = this.distanceFromCam * bottleSize;
    let camQuat = this.camera.camera.quaternion;
    let contQuat = this.camera.container.quaternion;
    let cameraForward = new Vector3(0, 0.05, -distance)
      .applyQuaternion(contQuat)
      .applyQuaternion(camQuat);

    this.targetPosition = this.camera.container.position.clone();
    this.targetPosition.add(cameraForward);

    this.originalBottlePosition = bottle.position.clone();
    this.originalBottleQuaternion = bottle.quaternion.clone();
    this.selectedBottle = bottle;
    gsap.to(this.selectedBottle.position, {
      x: this.targetPosition.x,
      y: this.targetPosition.y,
      z: this.targetPosition.z,
      duration: 2.5,
      ease: "expo.out"
    })
  }

  unselectBottle() {
    this.selectedBottle.traverse(node => {
      node.layers.set(0);
    })
    this.unselectedBottles.push({
      bottle: this.selectedBottle,
      originalPosition: this.originalBottlePosition,
      originalQuaternion: this.originalBottleQuaternion,
    });
    gsap.to(this.selectedBottle.position, {
      x:this.originalBottlePosition.x,
      y:this.originalBottlePosition.y,
      z:this.originalBottlePosition.z,
      duration:2,
      ease:"expo.out"
  } )
  gsap.to(this.selectedBottle.quaternion, {
    x:this.originalBottleQuaternion.x,
    y:this.originalBottleQuaternion.y,
    z:this.originalBottleQuaternion.z,
    w:this.originalBottleQuaternion.w,
    duration:2,
    ease:"expo.out"
})
    const productPage = document.querySelector('.product')
    productPage.classList.add('invisible')

    store.commit('setCurrentPage', 'explore')
    this.selectedBottle = null;
    setTimeout(() => {
      store.commit('setBottle', {})
      this.raycastManager.hideGhost()
    },2100)

  }
}
