import {
  Raycaster,
  Vector2,
  Vector3,
  Matrix4,
  Mesh,
  MeshNormalMaterial
} from "three";

export default class RaycastManager {
  constructor(params) {
    this.camera = params.camera;
    this.scene = params.scene;
    this.postProcessing = params.postProcessing;
    this.walls = params.world.gallery.walls;
    this.assets = params.assets
    this.time = params.time;
    this.objects = []
    this.pointer = new Vector3()
    this.raycaster = new Raycaster();
    this.smoothPointer = new Vector3(0, 0, 0);
    this.canMove = false;
    this.targetBottle = null;
    this.currentBottle = {
      type:null,
      matrix:new Matrix4(),
      position:new Vector3(),
      instanceId:null
    }
    this.hoveringGhost = false
    this.vintageInstance = this.scene.getObjectByName('Vintage Bottles')
    this.hasSelected = false
    this.init()
  }
  init() {

    const geo = this.assets.models.bottle.scene.getObjectByName("etiquette_1_lowpoly_1").geometry
    const mat = new MeshNormalMaterial()
    this.ghostBottle = new Mesh(
      geo,
      mat
     )
    this.ghostBottle.name = "Ghost Bottle Vintage"
    this.ghostBottle.token = {
      name:'GHOST BOTTLE',
      description:'Lorem ipsum dolor sit met consciuat nec mergitur senatus populus que romanus.',
      price:0.3
    }
    this.scene.add(this.ghostBottle)
    const list = ['ALL_ELEMENTS', 'Markers Container', 'Vintage Bottles', 'Ghost Bottle Vintage']
    list.forEach(item => {
      this.objects = this.objects.concat(this.scene.getObjectByName(item))
    })
  }

  cast(x, y) {
    const coord = new Vector2(
      (x / window.innerWidth) * 2 - 1,
      -((y / window.innerHeight) * 2 - 1)
    );

    this.canMove = false;
    this.postProcessing.outlinePass.selectedObjects = [];
    this.targetBottle = null;
    this.raycaster.setFromCamera(coord, this.camera.camera);
    const intersects = this.raycaster.intersectObjects(this.objects, true);
    if (intersects.length > 0) {
      this.pointer.set(
        intersects[0].point.x,
        intersects[0].point.y,
        intersects[0].point.z
      );

      let object = intersects[0].object;
      if (object.name.includes('Marker') && object.visible === true) {
        this.canMove = true
        this.direction = object.to
        document.body.style.cursor = "pointer";
      }
      else {
        this.canMove = false
        document.body.style.cursor = "normal";

      }
      this.postProcessing.outlinePass.selectedObjects = [this.ghostBottle];

      if(object.name === 'Vintage Bottles') {
        if(this.currentBottle.instanceId) return
        const matrix = new Matrix4()
        this.vintageInstance.getMatrixAt(intersects[0].instanceId, matrix)
        const pos = new Vector3()
        pos.setFromMatrixPosition(matrix)
        this.ghostBottle.position.copy(pos)
        document.body.style.cursor = "pointer"
        this.currentBottle = {
          type:'vintage',
          matrix,
          position:pos,
          instanceId:intersects[0].instanceId
        }
        this.ghostBottle.token = {
          name:'ÉDITION ' + this.currentBottle.type + ' #'+this.currentBottle.instanceId,
          price:0.3
        }
        this.canSelect = true
        const newMatrix = new Matrix4()
        newMatrix.copy(matrix)
        newMatrix.makeScale(0,0,0)
        this.vintageInstance.setMatrixAt(intersects[0].instanceId, newMatrix)
        this.vintageInstance.instanceMatrix.needsUpdate = true
      }
      if(object.name !== 'Vintage Bottles' && object.name !== 'Ghost Bottle Vintage' && this.currentBottle.instanceId && !this.hasSelected) {
        this.hideGhost()
      }
      // if (object.parent.name.includes("BOTTLE") && object.parent.visible) {
      //   if(object.parent.name === "BOTTLE_BLONDE" || object.parent.name=== "ROSE_BOTTLE") return
      //   this.postProcessing.outlinePass.selectedObjects = [object.parent];
      //   document.body.style.cursor = "pointer";
      //   this.targetBottle = object.parent;
      // }

    }
  }
  hideGhost() {
    this.canSelect = false

    this.vintageInstance.setMatrixAt(this.currentBottle.instanceId, this.currentBottle.matrix)
        this.vintageInstance.instanceMatrix.needsUpdate = true
        this.currentBottle = {}
        this.ghostBottle.position.set(new Vector3(0,-100,0))
        this.hasSelected=false
  }
}
