import * as THREE from 'three'

export default class CustomReflector extends THREE.Mesh {

    constructor(geometry, options = {}) {

        super(geometry);
        this.type = 'Reflector';
        const scope = this;
        const color = options.color !== undefined ? new THREE.Color(options.color) : new THREE.Color(0x7F7F7F);
        const textureWidth = options.textureWidth || 512;
        const textureHeight = options.textureHeight || 512;
        const clipBias = options.clipBias || 0;
        const shader = options.shader || this.getShader(); //

        const reflectorPlane = new THREE.Plane();
        const normal = new THREE.Vector3();
        const reflectorWorldPosition = new THREE.Vector3();
        const cameraWorldPosition = new THREE.Vector3();
        const rotationMatrix = new THREE.Matrix4();
        const lookAtPosition = new THREE.Vector3(0, 0, - 1);
        const clipPlane = new THREE.Vector4();
        const view = new THREE.Vector3();
        const target = new THREE.Vector3();
        const q = new THREE.Vector4();
        const textureMatrix = new THREE.Matrix4();
        const virtualCamera = new THREE.PerspectiveCamera();
        const parameters = {
            minFilter: THREE.LinearFilter,
            magFilter: THREE.LinearFilter,
            format: THREE.RGBFormat
        };
        this.renderTarget = new THREE.WebGLRenderTarget(textureWidth, textureHeight, parameters);
        const map = options.map;

        if (!THREE.MathUtils.isPowerOfTwo(textureWidth) || !THREE.MathUtils.isPowerOfTwo(textureHeight)) {

            this.renderTarget.texture.generateMipmaps = false;

        }

        const material = new THREE.ShaderMaterial({
            uniforms: THREE.UniformsUtils.clone(shader.uniforms),
            fragmentShader: shader.fragmentShader,
            vertexShader: shader.vertexShader
        });
        material.uniforms['tDiffuse'].value = this.renderTarget.texture;
        material.uniforms['color'].value = color;
        material.uniforms['textureMatrix'].value = textureMatrix;
        material.uniforms['map'].value = map
        this.material = material;

        this.onBeforeRender = function (renderer, scene, camera) {

            reflectorWorldPosition.setFromMatrixPosition(scope.matrixWorld);
            cameraWorldPosition.setFromMatrixPosition(camera.matrixWorld);
            rotationMatrix.extractRotation(scope.matrixWorld);
            normal.set(0, 0, 1);
            normal.applyMatrix4(rotationMatrix);
            view.subVectors(reflectorWorldPosition, cameraWorldPosition); // Avoid rendering when reflector is facing away

            if (view.dot(normal) > 0) return;
            view.reflect(normal).negate();
            view.add(reflectorWorldPosition);
            rotationMatrix.extractRotation(camera.matrixWorld);
            lookAtPosition.set(0, 0, - 1);
            lookAtPosition.applyMatrix4(rotationMatrix);
            lookAtPosition.add(cameraWorldPosition);
            target.subVectors(reflectorWorldPosition, lookAtPosition);
            target.reflect(normal).negate();
            target.add(reflectorWorldPosition);
            virtualCamera.position.copy(view);
            virtualCamera.up.set(0, 1, 0);
            virtualCamera.up.applyMatrix4(rotationMatrix);
            virtualCamera.up.reflect(normal);
            virtualCamera.lookAt(target);
            virtualCamera.far = camera.far; // Used in WebGLBackground

            virtualCamera.updateMatrixWorld();
            virtualCamera.projectionMatrix.copy(camera.projectionMatrix); // Update the texture matrix

            textureMatrix.set(0.5, 0.0, 0.0, 0.5, 0.0, 0.5, 0.0, 0.5, 0.0, 0.0, 0.5, 0.5, 0.0, 0.0, 0.0, 1.0);
            textureMatrix.multiply(virtualCamera.projectionMatrix);
            textureMatrix.multiply(virtualCamera.matrixWorldInverse);
            textureMatrix.multiply(scope.matrixWorld); // Now update projection matrix with new clip plane, implementing code from: http://www.terathon.com/code/oblique.html
            // Paper explaining this technique: http://www.terathon.com/lengyel/Lengyel-Oblique.pdf

            reflectorPlane.setFromNormalAndCoplanarPoint(normal, reflectorWorldPosition);
            reflectorPlane.applyMatrix4(virtualCamera.matrixWorldInverse);
            clipPlane.set(reflectorPlane.normal.x, reflectorPlane.normal.y, reflectorPlane.normal.z, reflectorPlane.constant);
            const projectionMatrix = virtualCamera.projectionMatrix;
            q.x = (Math.sign(clipPlane.x) + projectionMatrix.elements[8]) / projectionMatrix.elements[0];
            q.y = (Math.sign(clipPlane.y) + projectionMatrix.elements[9]) / projectionMatrix.elements[5];
            q.z = - 1.0;
            q.w = (1.0 + projectionMatrix.elements[10]) / projectionMatrix.elements[14]; // Calculate the scaled plane vector

            clipPlane.multiplyScalar(2.0 / clipPlane.dot(q)); // Replacing the third row of the projection matrix

            projectionMatrix.elements[2] = clipPlane.x;
            projectionMatrix.elements[6] = clipPlane.y;
            projectionMatrix.elements[10] = clipPlane.z + 1.0 - clipBias;
            projectionMatrix.elements[14] = clipPlane.w; // Render

            this.renderTarget.texture.encoding = renderer.outputEncoding;
            scope.visible = false;
            const currentRenderTarget = renderer.getRenderTarget();
            const currentXrEnabled = renderer.xr.enabled;
            const currentShadowAutoUpdate = renderer.shadowMap.autoUpdate;
            renderer.xr.enabled = false; // Avoid camera modification

            renderer.shadowMap.autoUpdate = false; // Avoid re-computing shadows

            renderer.setRenderTarget(this.renderTarget);
            renderer.state.buffers.depth.setMask(true); // make sure the depth buffer is writable so it can be properly cleared, see #18897

            if (renderer.autoClear === false) renderer.clear();
            renderer.render(scene, virtualCamera);
            renderer.xr.enabled = currentXrEnabled;
            renderer.shadowMap.autoUpdate = currentShadowAutoUpdate;
            renderer.setRenderTarget(currentRenderTarget); // Restore viewport

            const viewport = camera.viewport;

            if (viewport !== undefined) {

                renderer.state.viewport(viewport);

            }

            scope.visible = true;

        };

        this.getRenderTarget = function () {

            return this.renderTarget;

        };

    }

    getShader() {
        return {
            uniforms: {
                'color': {
                    value: null
                },
                'tDiffuse': {
                    value: null
                },
                'textureMatrix': {
                    value: null
                },
                'map': {
                    value: null
                }
            },
            vertexShader:
                /* glsl */`
    
            uniform mat4 textureMatrix;
    
            varying vec4 vUv;
            varying vec3 pos;
    
            void main() {
    
                vUv = textureMatrix * vec4( position, 1.0 );
                pos = position;
                gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
    
            }`,
            fragmentShader:
                /* glsl */`
    
            uniform vec3 color;
            uniform sampler2D tDiffuse;
            uniform sampler2D map;
    
            varying vec4 vUv;
            varying vec3 pos;
    
            float blendOverlay( float base, float blend ) {
    
                return( base < 0.5 ? ( 2.0 * base * blend ) : ( 1.0 - 2.0 * ( 1.0 - base ) * ( 1.0 - blend ) ) );
    
            }
    
            vec3 blendOverlay( vec3 base, vec3 blend ) {
    
                return vec3( blendOverlay( base.r, blend.r ), blendOverlay( base.g, blend.g ), blendOverlay( base.b, blend.b ) );
    
            }

            vec4 blur9(sampler2D image, vec2 uv, vec2 resolution, vec2 direction) {
                vec4 color = vec4(0.0);
                vec2 off1 = vec2(1.3846153846) * direction;
                vec2 off2 = vec2(3.2307692308) * direction;
                color += texture2D(image, uv) * 0.2270270270;
                color += texture2D(image, uv + (off1 / resolution)) * 0.3162162162;
                color += texture2D(image, uv - (off1 / resolution)) * 0.3162162162;
                color += texture2D(image, uv + (off2 / resolution)) * 0.0702702703;
                color += texture2D(image, uv - (off2 / resolution)) * 0.0702702703;
                return color;
            }
    
            void main() {
                vec2 uv = vec2 (vUv.s / vUv.q, vUv.t / vUv.q);
                vec4 base = blur9(tDiffuse,uv,vec2(1000), vec2(1.));
                vec2 p = mod(pos.xy/8., vec2(1.));
                vec4 map = texture2D(map, p);
                
                gl_FragColor = vec4( map.rgb * (1.+base.rgb*1.5) , 1.0 );    
            }`
        };
    }

}


