import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      layout: "default",
    },
  },
  {
    path: "/explore",
    name: "Explore",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Explore.vue"),
    meta: {
      layout: "three",
    },
  },
  {
    path:"/thanks",
    name:"Thanks",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Thanks.vue"),
      meta: {
        layout: "default",
      },
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
