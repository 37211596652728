import {
  Object3D,
  PerspectiveCamera,
  Vector3,
  Quaternion,
} from "three";
import Rails from './Rails'

export default class Camera {
  constructor(params) {
    this.debug = params.debug;
    this.time = params.time;
    this.scene = params.scene;
    this.renderer = params.renderer;
    this.target = new Vector3()
    this.lastTarget = new Vector3()
    this.targetPosition = new Vector3(0.3, 1.7, 11);
    this.identity = new Quaternion(0, 1, 0, 0)
    this.scrolling = false
    this.sizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
    this.container = new Object3D()

    this.init();
    if (this.debug) this.setDebug();

    this.time.on("tick", this.move.bind(this));
  }
  init() {
    this.camera = new PerspectiveCamera(
      80,
      window.innerWidth / this.sizes.height,
      0.01,
      1000
    );
    this.camera.layers.enable(20)
    this.camera.rotateY(Math.PI)
    this.camera.setFocalLength(20)
    this.rails = new Rails({
      camera: this.camera,
      scene: this.scene
    })
    this.container.position.clone(this.targetPosition);
    this.container.add(this.camera)
  }
  resize() {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.camera.setFocalLength(20)
  }
  setDebug() {
    const folder = this.debug.addFolder("Camera");
    folder.add(this.camera.position, "x", -500, 500, 0.4);
    folder.add(this.camera.position, "y", -500, 500, 0.4);
    folder.add(this.camera.position, "z", -500, 500, 0.4);
    folder.open();
  }

  move() {
    if (this.scrolling) this.camera.quaternion.slerp(this.identity, 0.1)

    this.container.position.lerp(this.targetPosition, 0.1);
    this.target.lerp(this.lastTarget, 0.04)
    this.container.lookAt(this.target)
  }

  moveTo(target) {
    this.targetPosition = target.clone();
  }
  moveToPercentage(percentage) {
    const path = this.rails.path
    percentage = percentage % 1
    if(percentage<0.05) percentage+=1
    let p1 = path.getPointAt(percentage % 1);
    let p2;
    if(this.forward) p2 = path.getPointAt((percentage + 0.1) % 1);
    else p2 = path.getPointAt((percentage - 0.05) % 1);
    this.lastTarget.set(p2.x, 1.75, p2.z)
    this.moveTo(
      new Vector3(
        p1.x, 1.75, p1.z
      ),
    );
  }
}
