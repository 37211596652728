import {
  createStore
} from 'vuex'

export default createStore({
  state: {
    loaded: false,
    currentBottle:{},
    currentPage:'explore',
    account: null,
    customer:null,
    transaction:null
  },
  mutations: {
    setLoaded(state, data) {
      state.loaded = data
    },
    setBottle(state, data) {
      state.currentBottle = data
    },
    setCurrentPage(state, data) {
      state.currentPage = data
    },
    setAccount(state, data) {
      state.account = data
      state.customer = {
        wallet:data.address
      }
    },
    setCustomer(state, data) {
      state.customer = data
    },
    setTransaction(state, data) {
      state.transaction = data
    }
  },
  getters: {
    loaded(state) {
      return state.loaded
    },
    bottle(state) {
      return state.currentBottle
    },
    currentPage(state) {
      return state.currentPage
    },
    account(state) {
      return state.account
    },
    customer(state) {
      return state.customer
    },
    transaction(state) {
      return state.transaction
    }
  },
  actions: {},
  modules: {}
})