import { Object3D, PlaneGeometry} from "three";
import Lights from "./Lights";
import { Reflector } from 'three/examples/jsm/objects/Reflector';

import Gallery from "./Gallery";
import Ground from "./Ground";

export default class World {
  constructor(params) {
    this.time = params.time;
    this.assets = params.assets;
    this.debug = params.debug;
    this.camera = params.camera;
    this.container = new Object3D();
    this.container.name = "World";
    this.tokens = params.tokens
    this.BLOOM_SCENE = params.BLOOM_SCENE
    this.init();
  }
  init() {
    this.addGallery();
    this.addLights();
    this.addGround();
    // this.addRoof()
  }

  addGallery() {
    this.gallery = new Gallery({
      assets: this.assets,
      time: this.time,
      camera: this.camera,
      tokens: this.tokens
    });

    this.container.add(this.gallery.mesh);
  }


  addLights() {
    this.lights = new Lights();

    this.container.add(this.lights.container);
  }

  addGround() {
    this.ground = new Ground({ gallery: this.gallery, texture: this.assets.textures.ground })
    this.container.add(this.ground.container)
  }
  addRoof() {
    const geo = new PlaneGeometry(100,100)
   
    const mirror = new Reflector(geo, {
      clipBias: 0.3,
					textureWidth: window.innerWidth * window.devicePixelRatio,
					textureHeight: window.innerHeight * window.devicePixelRatio,
					color: 0x222222
    })
    mirror.position.y+=3
    mirror.rotateX(Math.PI/2)
    this.container.add(mirror)

  }
}
