import {CatmullRomCurve3, Vector3} from 'three'
export default class Rails {
    constructor(params) {
        this.scene = params.scene
        this.points = [[-1.016113042831421, -9.449840545654297, 0.0] ,
        [-2.235445499420166, -2.2693166732788086, 0.0] ,
        [-4.4031524658203125, 0.7112798690795898, 0.0] ,
        [-4.4031524658203125, 4.572503089904785, 0.0] ,
        [-7.519227027893066, 10.804654121398926, 0.0] ,
        [-9.686933517456055, 16.427139282226562, 0.0] ,
        [-4.741855621337891, 15.88521671295166, 0.0] ,
        [2.3709261417388916, 15.817475318908691, 0.0] ,
        [4.877336502075195, 15.88521671295166, 0.0] ,
        [8.535337448120117, 11.61754322052002, 0.0] ,
        [4.470890998840332, 4.030575752258301, 0.0] ,
        [2.912855386734009, -0.6435375213623047, 0.0] ,
        [0.9483679533004761, -9.653061866760254, 0.0] ,
        [0.5131847858428955, -9.736032485961914, -3.4814281463623047] ,
        [0.4420280456542969, -9.534177780151367, -3.398958206176758] ]
        this.init()
    }
    init() {
for (let i = 0; i < this.points.length; i++) {
    const x = this.points[i][0];
    const y = this.points[i][1];
    const z = this.points[i][2];
    this.points[i] = new Vector3(x, z, -y);
  }

//========== Create a path from the this.points
this.path =  new CatmullRomCurve3(this.points)
    }

}