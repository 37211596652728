<template>
  <header>
    <img src="@/assets/images/logo_collab.svg" alt="Dom Pérignon x Lady Gaga" />
    <ul>
      <li><a href="#">La collaboration</a></li>
      <li><a href="#">Édition limitée NFT</a></li>
      <li><a href="#">Uber Piece</a></li>
      <li><a href="#">Pop-up store NFT</a></li>
      <li><a href="#">Me tenir informé</a></li>
    </ul>
  </header>
</template>
