import {
    EffectComposer
} from 'three/examples/jsm/postprocessing/EffectComposer.js';
import {
    RenderPass
} from 'three/examples/jsm/postprocessing/RenderPass.js';
import {
    UnrealBloomPass
} from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import {
    ShaderPass
} from 'three/examples/jsm/postprocessing/ShaderPass.js'

import {
    SMAAPass
} from 'three/examples/jsm/postprocessing/SMAAPass.js';


import {
    OutlinePass
} from 'three/examples/jsm/postprocessing/OutlinePass.js';

import {
    Layers,
    MeshBasicMaterial,
    ShaderMaterial,
    Vector2,
    Vector3
} from 'three';

import finalPassVert from '@/assets/shaders/finalPassVert.glsl'
import finalPassFrag from '@/assets/shaders/finalPassFrag.glsl'

export default class PostProcessing {
    constructor(params) {
        this.renderer = params.renderer.renderer
        this.time = params.time
        this.camera = params.camera
        this.scene = params.scene
        this.BLOOM_SCENE = params.BLOOM_SCENE
        this.composer = new EffectComposer(this.renderer)
        this.materials = []
        this.focusPosition = new Vector3(0, 0, 0)
        this.focused = false
        this.pr = this.renderer.getPixelRatio()
        /** Selective Bloom Utils */
        this.bloomLayer = new Layers()
        this.bloomLayer.set(this.BLOOM_SCENE)
        this.darkMaterial = new MeshBasicMaterial({color:0x000000})
        /** End Selective Bloom Utils */
        this.setRenderPass()
        this.setBloomPass()
        this.setFinalPass()

        this.setGrainPass()
        this.setOutlinePass()
        this.setVignettePass()
        this.setSMAAPass()
        this.render()
        if (this.debug) this.setDebug()
    }

    setRenderPass() {
        this.renderPass = new RenderPass(this.scene, this.camera.camera);
        this.composer.addPass(this.renderPass);
    }

    setBloomPass() {
        //resolution: Vector2, strength: number, radius: number, threshold: number
        this.bloomPass = new UnrealBloomPass(new Vector2(window.innerWidth * this.pr, window.innerHeight * this.pr), 1, 0.8, 0.8)
        this.composer.addPass(this.bloomPass);
        this.composer.renderToScreen = false
    }

    setOutlinePass() {
        this.outlinePass = new OutlinePass(new Vector2(window.innerWidth * this.pr, window.innerHeight * this.pr), this.scene, this.camera.camera);
        this.outlinePass.edgeStrength = 3;
        this.outlinePass.edgeGlow = 1;
        this.outlinePass.edgeThickness = 2;
        this.outlinePass.visibleEdgeColor.set(0xffffff);
        this.outlinePass.hiddenEdgeColor.set(0x202020);
        this.finalComposer.addPass(this.outlinePass);
    }

    setGrainPass() {
        //custom shader pass
        let grainAmount = '0.05'
        let vertShader = /* glsl */`
            varying vec2 vUv;
            void main() {
            vUv = uv;
            gl_Position = projectionMatrix 
                * modelViewMatrix 
                * vec4( position, 1.0 );
            }
        `
        let fragShader = /* glsl */`
            uniform float amount;
            uniform sampler2D tDiffuse;
            varying vec2 vUv;

            float random( vec2 p )
            {
                vec2 K1 = vec2(
                23.14069263277926, // e^pi (Gelfond's constant)
                2.665144142690225 // 2^sqrt(2) (Gelfondâ€“Schneider constant)
                );
                return fract( cos( dot(p,K1) ) * 12345.6789 );
            }

            void main() {

                vec4 color = texture2D( tDiffuse, vUv );
                vec2 uvRandom = vUv;
                uvRandom.y *= random(vec2(uvRandom.y,amount));
                color.rgb += random(uvRandom)*${grainAmount};
                gl_FragColor = vec4( color  );
            }
        `

        let myEffect = {
            uniforms: {
                "tDiffuse": {
                    value: null
                },
                "amount": {
                    value: this.grainCounter
                }
            },
            vertexShader: vertShader,
            fragmentShader: fragShader
        }

        this.grainPass = new ShaderPass(myEffect);
        this.grainPass.counter = 0.0;
        this.grainPass.material.opacity = 0
        this.grainPass.renderToScreen = true;
        this.finalComposer.addPass(this.grainPass);


        this.time.on('tick', () => {
            this.grainPass.uniforms['amount'].value = (this.grainPass.counter += 0.001)
        })

    }

    setVignettePass() {
        let vertShader = /* glsl */`
            varying vec2 vUv;

            void main() {
            vUv = uv;
            gl_Position = projectionMatrix 
                * modelViewMatrix 
                * vec4( position, 1.0 );
            }
        `
        let fragShader = /* glsl */`
                uniform sampler2D tDiffuse;
                uniform float width;
                uniform float height;
                varying vec2 vUv;

                vec4 blur9(sampler2D image, vec2 uv, vec2 resolution, vec2 direction) {
                    vec4 color = vec4(0.0);
                    vec2 off1 = vec2(1.3846153846) * direction;
                    vec2 off2 = vec2(3.2307692308) * direction;
                    color += texture2D(image, uv) * 0.2270270270;
                    color += texture2D(image, uv + (off1 / resolution)) * 0.3162162162;
                    color += texture2D(image, uv - (off1 / resolution)) * 0.3162162162;
                    color += texture2D(image, uv + (off2 / resolution)) * 0.0702702703;
                    color += texture2D(image, uv - (off2 / resolution)) * 0.0702702703;
                    return color;
                }

                void main() {
                    float vignette = distance(vUv, vec2(0.5));
                    vignette = smoothstep(0.3, 0.9, vignette);
                    vec4 color = blur9(tDiffuse, vUv, vec2(width,height), vec2(vignette)*5.);

                    gl_FragColor = color*(1.0-vignette);
                }
            `

        let myEffect = {
            uniforms: {
                "tDiffuse": {
                    value: null
                },
                "width": {
                    value: window.innerWidth
                },
                "height": {
                    value: window.innerHeight
                }
            },
            vertexShader: vertShader,
            fragmentShader: fragShader
        }

        this.vignettePass = new ShaderPass(myEffect);

        this.vignettePass.renderToScreen = true;
        this.vignettePass.material.transparent = true;
        this.finalComposer.addPass(this.vignettePass);
    }

    setSMAAPass() {
        this.SMAAPass = new SMAAPass(window.innerWidth * this.pr, window.innerHeight * this.pr);
        this.composer.addPass(this.SMAAPass)
        this.finalComposer.addPass(this.SMAAPass)
    }

    setFinalPass() {
        this.finalComposer = new EffectComposer(this.renderer)
        this.finalPass = new ShaderPass(new ShaderMaterial({
            uniforms: {
                baseTexture: {value: null},
                bloomTexture: {value : this.composer.renderTarget2.texture}
            },
            vertexShader:finalPassVert,
            fragmentShader:finalPassFrag,
            defines:{}
        }), "baseTexture")

        this.finalComposer.addPass(this.renderPass)
        this.finalComposer.addPass(this.finalPass)
    }
    /** Selective Bloom Render Functions */
    darkenNonBloomed( obj ) {
        if ( obj.isMesh && this.bloomLayer.test( obj.layers ) === false ) {
            this.materials[ obj.uuid ] = obj.material;
            obj.material = this.darkMaterial;
        }
    }
    restoreMaterial( obj ) {
        if ( this.materials[ obj.uuid ] ) {
            obj.material = this.materials[ obj.uuid ];
            delete this.materials[ obj.uuid ];
        }
    }
    /** End Selective Bloom Render Functions */
    render() {
        this.time.on('tick', () => {
             this.scene.traverse(this.darkenNonBloomed.bind(this))
             this.composer.render()
             this.scene.traverse(this.restoreMaterial.bind(this))
             this.finalComposer.render()
        })
    }

    resize() {
        let rez = new Vector2(window.innerWidth * this.pr, window.innerHeight * this.pr)

        this.vignettePass.uniforms["width"].value = window.innerWidth;
        this.vignettePass.uniforms["height"].value = window.innerHeight;

        this.composer.setSize(rez.x, rez.y);
    }
}
